import { BrowserRouter, Routes, Route } from "react-router-dom";
import IniciarSesion from "./pages/IniciarSesion";
import Dashboard from "./pages/Dashboard";
import Pantalla1 from "./pages/Pantalla1";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IniciarSesion/>}></Route>
        <Route path="/signin" element={<IniciarSesion/>}></Route>
        <Route path="/dashboard" element={<Dashboard/>}></Route>
        <Route path="/pantalla1" element={<Pantalla1/>}></Route>
      </Routes>    
    </BrowserRouter>
  );
}

export default App;
