import React , {useState} from 'react'
import { NavLink } from 'react-router-dom';

export default function IniciarSesion() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar correo electrónico
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Por favor ingrese un correo electrónico válido.');
    } else {
      setEmailError('');
    }

    // Validar contraseña
    if (!password) {
      setPasswordError('Por favor ingrese una contraseña.');
    } else {
      setPasswordError('');
    }

    // Si todo está bien, continuar con el envío del formulario
    if (email && password && /\S+@\S+\.\S+/.test(email)) {
      // Aquí podrías enviar los datos del formulario
      console.log('Correo Electrónico:', email);
      console.log('Contraseña:', password);
    }
  };

  return (
    <div className='h-screen bg-gray-100 flex items-center justify-center'>        
        <form onSubmit={handleSubmit}>
          <div className='bg-white w-96 p-6 rounded-md shadow-lg'>
            <div className='flex items-center justify-center mb-4'>
              <img src='/logo192.png' alt='logo-inicio' className='h-32 App-logo'/>
            </div>

            <label className="text-gray-700" htmlFor='email'>Correo Electrónico</label>              
            <input id="email"
              className={`w-full py-2 bg-gray-50 text-gray-500 px-2 outline-none mb-2 rounded-md text-sm
              placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              border ${emailError ? 'border-red-500' : 'border-slate-300'}`}
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
            {emailError && <p className="text-red-500 text-xs -mt-1">{emailError}</p>}

            <label className="text-gray-700" htmlFor="password">Contraseña</label>              
            <input id="password"
              className={`w-full py-2 bg-gray-50 text-gray-500 px-2 outline-none mb-2 rounded-md text-sm
              placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              border ${passwordError ? 'border-red-500' : 'border-slate-300'}`}
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            {passwordError && <p className="text-red-500 text-xs -mt-1">{passwordError}</p>}

            <button type="submit" className="bg-sky-500 w-full
             text-white py-2 rounded-md hover:bg-sky-700 mt-4
             transition-colors font-semibold leading-6
             focus:ring-2 hover:ring-sky-500"
            >Iniciar Sesión</button>
            
            
          </div>
        </form>

        <div className='flex flex-col ml-4'>
          <h2 className='mb-4'>Temporal: </h2>
          <NavLink to="/dashboard" className='bg-blue-500 hover:bg-blue-700 text-white rounded py-2 px-4 mb-3'>Dashboard</NavLink>
          <NavLink to="/pantalla1" className='bg-blue-500 hover:bg-blue-700 text-white rounded py-2 px-4'>Pantalla1</NavLink>
        </div>    
    </div>
  )
}
