import React from 'react'
import Menu from '../layout/Menu'

export default function Dashboard() {
  return (
    <div className='h-screen bg-gray-100 flex'>
        <Menu/>

        
        Contenido


    </div>
  )
}
