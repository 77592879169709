import React from 'react'
import Menu from '../layout/Menu'

export default function Pantalla1() {
  return (
    <div className='h-screen bg-gray-100 flex'>
      <Menu/>
      Pantalla1
    </div>
  )
}
