import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Menu() {
  return (
    <div className='bg-green-200 w-48 flex flex-col'>
        <h1 className='text-3xl text-center mb-4'>MENU</h1>
        <NavLink to="/dashboard" className="bg-blue-500 hover:bg-blue-700 text-white mb-3 py-4 text-center">Dashboard</NavLink>
        <NavLink to="/pantalla1" className="bg-blue-500 hover:bg-blue-700 text-white mb-3 py-4 text-center">Pantalla1</NavLink>
        <NavLink to="/" className="bg-blue-500 hover:bg-blue-700 text-white mb-3 py-4 text-center">Volver</NavLink>
    </div>
  )
}
